<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Logs: Solicita tu Crédito</h1>
    </div>

    <div class="row mb-3">
      <div class="col-sm-6">
        <template v-if="options.filters.length > 0" >
        Filtros activos:
        <table class="filtros_table">
          <thead>
            <tr>
              <th>Columna</th>
              <th>Valor</th>
              <th>Operador</th>
              <th>Obligatorio</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(filtro, index) in options.filters">
              <td>{{ filtro.columna }}</td>
              <td><input type="text" :name="'filter_value_'+index" :id="'filter_value_'+index" :value="filtro.valor1" class="form-control" :data-index="index" :data-columna="'valor1'" @change="actualizar_filtro"></td>
              <td><select :name="'filter_operador_'+index" :id="'filter_operador_'+index" class="form-control" :data-index="index" :data-columna="'operador'" @change="actualizar_filtro">
                <option value="equal_to" :selected="filtro.operador == 'equal_to'">Igual</option>
                <option value="not_equal_to" :selected="filtro.operador == 'not_equal_to'">No es igual</option>
                <option value="contains" :selected="filtro.operador == 'contains'">Contiene</option>
                <option value="starts_with" :selected="filtro.operador == 'starts_with'">Inicia con</option>
                <option value="ends_with" :selected="filtro.operador == 'ends_with'">Termina con</option>
                <!-- <option value="between" :selected="filtro.operador == 'between'">Entre</option>
                <option value="not_between" :selected="filtro.operador == 'not_between'">No entre</option> -->
              </select></td>
              <td><select :name="'filter_relacion_'+index" :id="'filter_relacion_'+index" class="form-control" :data-index="index" :data-columna="'relacion'" @change="actualizar_filtro">
                <option value="and">Obligatorio</option>
                <option value="or">Opcional</option>
              </select></td>
              <td><i class="fa-solid fa-trash-can" @click="quitar_filtro(index)"></i></td>
            </tr>
          </tbody>
        </table>
        </template>
      </div>
      <div class="col-sm-6 text-right">
        <button class="btn btn-warning mr-2" @click="obtener_logs">Recargar</button>
        <button v-if="options.filters.length > 0" class="btn btn-primary mr-2" @click="quitar_filtros">Quitar filtros</button>
        <button class="btn btn-info" @click="exportar_logs">Exportar datos</button>
      </div>
    </div>

    <Paginacion :data="logs" @paginado="update_options" />

    <table class="logs_table">
      <thead>
        <tr>
          <th>#</th>
          <th>Sitio</th>
          <th>Uuid</th>
          <th>Origen IP</th>
          <th>Utm</th>
          <th>Url</th>
          <th>Evento</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(log, index) in logs.data" :key="index">
          <td><a href="#" title="Detalles" @click.prevent="ver_detalle=log._id"><i class="fa-regular fa-folder-open"></i></a></td>
          <td :title="log.site"><a href="#" @click.prevent="generar_filtro('site', log.site)">{{ text_formarter(log.site) }}</a></td>
          <td :title="log.uuid"><a href="#" @click.prevent="generar_filtro('uuid', log.uuid)">{{ text_formarter(log.uuid) }}</a></td>
          <td :title="log.origen_ip"><a href="#" @click.prevent="generar_filtro('origen_ip', log.origen_ip)">{{ text_formarter(log.origen_ip) }}</a></td>
          <td><a href="#" @click.prevent="generar_filtro('utm', log.utm)">{{ log.utm ? log.utm : '--' }}</a></td>
          <td :title="log.url"><a href="#"  @click.prevent="generar_filtro('url', log.url)">{{ text_formarter(log.url) }}</a></td>
          <td :title="log.evento"><a href="#" @click.prevent="generar_filtro('evento', log.evento)">{{ text_formarter(log.evento) }}</a></td>
          <td :title="$moment(log.created_at).format('MMMM dddd DD YYYY - HH:mm')">{{ $moment(log.created_at).format('ddd DD MMM YY - HH:mm')+' hrs' }}</td>
        </tr>
      </tbody>
    </table>

    <Paginacion :data="logs" @paginado="update_options" />

    <Detalle v-if="ver_detalle" :id="ver_detalle" @close="ver_detalle=null" />
  </div>
</template>

<script>
import DataGridV2 from '@/components/DataGridV2';
import Paginacion from '@/apps/alianzas_logs/components/Paginacion';
import Detalle from '@/apps/alianzas_logs/pages/Detalle';

import api from '@/apps/alianzas_logs/api/logs.js';

export default {
  components: {
    DataGridV2, Paginacion, Detalle
  },
  data() {
    return {
      logs: [],
      options: {
        page: 1,
        limit: 20,
        order_col: '_id',
        order_dir: 'desc',
        filters: [],
        columns: ['_id','uuid','origen_ip','utm','url','evento','site','created_at']
      },
      ver_detalle: null
    }
  },
  mounted() {
    this.obtener_logs();
  },
  methods: {
    async obtener_logs() {
      try {
        this.$log.info('obtener_logs');
        let logs = (await api.obtener_logs(this.options)).data;

        for (let i = 0; i < logs.data.length; i++) {
          if (!logs.data[i].site) {
            let url = logs.data[i].url;
            let site = url.substring(url.indexOf('//')+2);
            site = site.substring(0, site.indexOf('/'));
            logs.data[i].site = site;
          }
        }

        this.logs = logs;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    text_formarter(text) {
      if (typeof text != 'string')
        text = JSON.stringify(text);

      let text_length= 30;
      text = text.length > text_length ? text.substring(0, text_length) + '...' : text;
      return text;
    },
    update_options(options) {
      this.options = Object.assign(this.options, options);
      this.obtener_logs();
    },
    generar_filtro(col, data) {
      let filter = {
        relacion: 'and',
        columna: col,
        operador: 'equal_to',
        valor1: data
      }

      this.options.filters.push(filter);
      this.options.page = 1;

      this.obtener_logs();
    },
    quitar_filtros() {
      this.options.filters = [];

      this.obtener_logs();
    },
    quitar_filtro(index) {
      this.options.filters.splice(index, 1);
      this.options.page = 1;
      this.obtener_logs();
    },
    actualizar_filtro(e) {
      console.log('se actualiza campo');
      let columna = e.target.dataset.columna;
      let index = e.target.dataset.index;
      console.log('index',index);
      console.log('columna',columna);

      this.options.filters[index][columna] = e.target.value;

      this.obtener_logs();
    },
    async exportar_logs() {
      try {
        this.$log.info('exportar_logs');
        let res = (await api.exportar_logs({
          filtros: this.options.filters
        }, )).data;
        this.$helper.showMessage('Exito!','Se enviará un correo con el excel en cuanto termine de generarse', 'success', 'alert');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logs_table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;

  thead {
    tr {
      th {
        background-color: grey !important;
        padding: 5px 10px;
      }

      th:nth-child(1) {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      td:nth-child(1) {
        text-align: center;
        padding: 10px;
      }
    }

    tr:nth-child(2n+0) {
      background-color: hsl(0, 0%, 90%);
    }

    tr:hover {
      background-color: rgb(207, 255, 197);
    }
  }
}

.filtros_table {
  width: 100%;

  thead {
    tr {
      th {
        background-color: grey !important;
        padding: 5px;
      }
    }
  }

  tbody {
    tr {
      td:nth-child(5) {
        text-align: center;
      }
    }
  }
}
</style>